import React from 'react'

import SiteLayout from '../layout/SiteLayout'
import Container from '../components/Container'
import SEO from '../components/SEO'
import GoogleMap from '../components/GoogleMap'
import { Title, TitleContainer, TitleIntro } from '../components/Title'
import ContactForm from '../components/ContactForm'
import styles from './contact-us.module.scss'

const ContactUs = () => {
  return (
    <SiteLayout>
      <SEO title="Contact us" />
      <TitleContainer>
        <Title>Contact us</Title>
        <TitleIntro>
          Feel free to get in touch with our friendly team - they’ll be happy to
          help!
        </TitleIntro>
      </TitleContainer>
      <section className="bg-gray">
        <Container>
          <div className={styles.contact}>
            <div className={styles.info}>
              <div className={styles.address}>
                <h5>Office Address</h5>
                <p>Dudley Court South, The Waterfront</p>
                <p>Brierley Hill, West Midlands, DY5 1XN.</p>
              </div>
              <div className={styles.contactInfo}>
                <h5>Contact Info</h5>
                <span>
                  Phone: <a href="tel:+441384906007">01384 906 007</a>
                </span>
                <span>
                  Email:{' '}
                  <a href="mailto:hello@airaccounting.com">
                    hello@airaccounting.com
                  </a>
                </span>
              </div>
            </div>
            <div className={styles.form}>
              <ContactForm />
            </div>
          </div>
        </Container>
      </section>
      <section className="bg-white" style={{ height: '500px' }}>
        <GoogleMap />
      </section>
    </SiteLayout>
  )
}

export default ContactUs
